import { defineStore } from 'pinia'
import type { Category } from '~/utils/types/inventory'
import type {
    Facet,
    FacetParam,
    FacetStat,
    GlobalFilters,
} from '~/utils/types/inventoryFilter'
import { useFetchAutoznetwork } from '~/composables/fetch/useFetchAutoznetwork'
import { useDomainStore } from '~/stores/domain'
import filterSchema from '~/utils/filterSchema'

export const useInventoryFilterStore = defineStore('inventoryFilter', {
    state: (): State => ({
        facets: {},
        rawFacets: {},
        facetStats: {},
        filters: {},
        customQueryFilters: {},
        filtersResetting: false,
        filtersSyncing: false,
        mobileFiltersOpen: false,
        showNavigationBar: true,
        searchResultsPage: false,
        currentTab: null,
        typeQueryActive: false,
        globalFilters: {},
    }),
    getters: {
        getFacets(state) {
            return state.facets
        },
        getFacetStats(state) {
            return state.facetStats
        },
        getFilters(state) {
            return state.filters
        },
        // getFiltersCount(state) {
        //     return state.filters.count()
        // },
        getLocationFacets(state) {
            return state.facets['location.name']
        },
        getMakeModelFacets(state) {
            return state.facets['make_model.lvl1']
        },
        getMakeFacets(state) {
            return state.facets.make
        },
        getModelFacets(state) {
            return state.facets.model
        },
        getMobileFilterIsOpen(state) {
            return state.mobileFiltersOpen
        },
        getIsSearchResultsPage(state) {
            return state.searchResultsPage
        },
        getShowNavigationBar(state) {
            return state.showNavigationBar
        },
        getCurrentTab(state) {
            return state.currentTab
        },
    },
    actions: {
        toggleMobileFilter(): void {
            this.mobileFiltersOpen = !this.mobileFiltersOpen
        },
        closeMobileFilter(): void {
            this.mobileFiltersOpen = false
        },
        resetPageRoute(): void {
            this.showNavigationBar = true
            this.searchResultsPage = false
            this.currentTab = null
        },
        updatePageRoute(url: string): void {
            this.resetPageRoute()

            if (url === '/') {
                this.currentTab = 'home'
            } else if (
                url.startsWith('/inventory') ||
                url.startsWith('/for-sale')
            ) {
                if (url.startsWith('/inventory')) {
                    this.searchResultsPage = true
                }
                this.currentTab = 'inventory'
            } else if (url.startsWith('/finance-application')) {
                this.showNavigationBar = false
                this.currentTab = 'finance'
            } else if (url.startsWith('/contact')) {
                this.currentTab = 'contact'
            }
        },
        async fetchFacets(
            query: Record<string, string | number | undefined> = {},
            category: Category | null = null,
        ): Promise<void> {
            const domainStore = useDomainStore()
            const params: Record<string, string | number | undefined> = {}

            if (category) {
                params.categories = category.name
            }

            // do type check until we implement facet updates on every req
            const type: string | number | null = query?.type ?? null

            if (type === 'New' || type === 'Used') {
                params.type = type
            }

            const facetAttributes: string | null = domainStore.facetAttributes
                ? domainStore.facetAttributes.join(',')
                : null

            const { data: res } = await useFetchAutoznetwork(
                '/inventory_facets',
                {
                    query: {
                        ...params,
                        ...(domainStore.getDefaultInventoryQuery ?? {}),
                        ...{
                            attributes: facetAttributes || undefined,
                        },
                    },
                    fetch: true,
                },
            )

            if (res.value) {
                this.rawFacets = res.value.facets
                this.facets = normalizeFacets(res.value.facets, query ?? {})
                this.facetStats = res.value.stats
            }
        },
        assignFilters(
            query: Record<string, string | number | undefined> = {},
        ): void {
            const customQueryFilters: Record<string, string[]> = {}
            const queryFilterKeys = Object.keys(filterSchema).filter(
                (key: string) => filterSchema[key].query,
            )

            const newQueryObj: Record<string, string | number | undefined> = {}
            const search: string = 'filters['

            Object.keys(query).forEach((key: string) => {
                if (queryFilterKeys.includes(key)) {
                    customQueryFilters[key] =
                        query[key]?.toString().split(',') ?? []
                } else if (key.startsWith(search)) {
                    const newKey: string = key.replace(search, '').slice(0, -1)
                    newQueryObj[newKey] = query[key]
                }
            })

            this.filters = newQueryObj
            this.customQueryFilters = customQueryFilters
        },
        updateFacet(params: FacetParam): void {
            const { filter, option, value, selectType, clearChildren } = params

            // update individual option
            const findIndex = this.facets[filter]?.findIndex(
                (item: any) => item.value === option,
            )

            if (findIndex > -1) {
                this.facets[filter][findIndex].checked = value

                // remove all other options for this facet
                if (selectType === 'radio') {
                    this.facets[filter].forEach(
                        (filter: any, index: number) => {
                            if (index !== findIndex) {
                                filter.checked = false
                            }
                        },
                    )
                }
            }

            if (clearChildren) {
                this.facets[clearChildren.key]
                    ?.filter(
                        (item: any) =>
                            item.checked &&
                            item.parent === clearChildren.parent,
                    )
                    .map((item: any) => {
                        item.checked = false
                        return item
                    })
            }
        },
        updateFilter(filterParam: FacetParam): void {
            const { filter, value } = filterParam

            if (value === null) {
                delete this.filters[filter]
            } else {
                this.filters[filter] = `${value.min} to ${value.max}`
            }
        },
        removeCustomQueryFilter(filterParam: FacetParam): void {
            const { filter, option } = filterParam

            if (this.customQueryFilters[filter]) {
                const index = this.customQueryFilters[filter].indexOf(option)

                if (index > -1) {
                    if (this.customQueryFilters[filter].length > 1) {
                        this.customQueryFilters[filter].splice(index, 1)
                    } else {
                        delete this.customQueryFilters[filter]
                    }
                }
            }
        },
        buildQueryFromFilters() {
            const returnObj: Record<string, any> = {}

            Object.keys(this.facets).forEach((facet: string) => {
                const curArr: any[] = []

                this.facets[facet].forEach((facetObj: Facet) => {
                    if (facetObj.checked) {
                        curArr.push(facetObj.value)
                    }
                })

                if (curArr.length > 0) {
                    returnObj[facet] = curArr.join(',')
                }
            })

            const customQueryFilters: Record<string, any> = {}

            Object.keys(this.customQueryFilters).forEach((key: string) => {
                customQueryFilters[key] = this.customQueryFilters[key].join(',')
            })

            return {
                ...returnObj,
                ...customQueryFilters,
                ...(Object.keys(this.filters).length > 0 &&
                    generateFilters(this.filters)),
            }
        },
        syncFacetsFromQuery(query: Record<string, any>) {
            this.facets = normalizeFacets(this.rawFacets, query)
        },
        reset() {
            // reset facets
            recursivelyResetFacets(this.facets)

            // reset filters
            this.filters = {}

            // reset custom query filters
            this.customQueryFilters = {}
        },
        async assignCachedFilters() {
            const { data } = await useFetch('/api/filters')

            if (data.value) {
                this.globalFilters = data.value
            }
        },
    },
})

function normalizeFacets(
    data: Record<string, any>,
    query: Record<string, any>,
): Record<string, Facet[]> {
    const returnObj: Record<string, any> = {}
    const parentChildFacets: string[] = [
        'make_model.lvl1',
        'location_search_term.lvl1',
        'bed_length_group.lvl1',
    ]

    Object.keys(data).forEach((facet: string) => {
        const facetData = data[facet]
        const options: any[] = []

        Object.keys(facetData).forEach((value: string) => {
            const count = facetData[value]

            // important: make the check case-insensitive
            const valueArr: string[] = query[facet]
                ? query[facet].toLowerCase().split(',')
                : []

            const optionObj: Facet = {
                value,
                count,
                checked: valueArr.includes(value.toLowerCase()),
            }

            if (parentChildFacets.includes(facet)) {
                const facetSplit: string[] = value.split(' > ')

                optionObj.parent = facetSplit[0]
                optionObj.child = facetSplit[1]
            }

            options.push(optionObj)
        })

        returnObj[facet] = options
    })

    return returnObj
}

function recursivelyResetFacets(obj: any) {
    for (const key in obj) {
        if (key === 'checked' && obj[key]) {
            obj[key] = false
        } else if (typeof obj[key] === 'object') {
            recursivelyResetFacets(obj[key])
        }
    }
}

function generateFilters(obj: Record<string, any>): Record<string, any> {
    const params: Record<string, any> = {}

    Object.keys(obj).forEach((key: string) => {
        Object.assign(params, { [`filters[${key}]`]: obj[key] })
    })

    return params
}

interface State {
    facets: Record<string, Facet[]>
    rawFacets: Record<string, any>
    facetStats: Record<string, FacetStat>
    filters: Record<string, any>
    customQueryFilters: Record<string, string[]>
    filtersResetting: boolean
    filtersSyncing: boolean
    mobileFiltersOpen: boolean
    showNavigationBar: boolean
    searchResultsPage: boolean
    currentTab: string | null
    typeQueryActive: boolean
    globalFilters: GlobalFilters | null
}

export interface Pill {
    key: string
    value: string
    name: string
    type: 'facet' | 'filter' | 'query'
    range?: string | undefined
    category?: string | undefined
}
